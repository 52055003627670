import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`We expand our growing team of talented individuals at Wizbord.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={4} colLg={8} mdxType="Column">
        <h2>{`Core team located in Silicon Valley`}</h2>
        <p>{`Our aim is for great people to work to create an ecosystem and to realize their full potential. We are designing proprietary customer software systems end-to-end for our Wizbord Knowledge team. We have many years of industry experience while constantly learning and introducing new technologies for our clients. We mainly create systems for current businesses and startups using web apps, interactive websites, mobile applications and other applications.`}</p>
        <p><a parentName="p" {...{
            "href": "mailto:jobs@wizbord.com"
          }}>{`Submit your resume`}</a></p>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      